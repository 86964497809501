<template>
  <div>
    <div class="dy">
      <div class="dycontent flex flex-center">
        <div class="flex flex-center">
          <img class="dycontent-img" :src="detail.avatar" alt="" />
          <div class="">
            <div class="flex flex-center">
              <div class="dycontent-name">{{ detail.name }}</div>
              <div class="dycontent-label flex flex-center">
                <img
                  class="dycontent-vipIcon"
                  src="../assets/imgs/vipIcon.png"
                  alt=""
                />
                <div>普通会员</div>
              </div>
            </div>
            <!-- <div class="dycontent-integral">900积分</div> -->
          </div>
        </div>
        <div class="dycontent-detail flex flex-center" @click="router('/Info')">
          详情
          <img
            class="dycontent-detailIcon"
            src="../assets/imgs/whileArrow.png"
            alt=""
          />
        </div>
      </div>

      <div class="dyList flex flex-center rel">
        <div
          class="dyList-item flex flex-center column rel"
          v-for="(item, index) in list"
          :key="index"
          @click="click(index)"
        >
          <img :src="item.img" alt="" />
          <div>{{ item.name }}</div>
          <div class="dyList-line abs" v-if="index != 2"></div>
        </div>
      </div>

      <div class="dyLabel flex column flex-center" @click="router('/Label')">
        <div class="dyLabel-top flex flex-center">
          <div>标签</div>
          <img src="../assets/imgs/arrow.png" alt="" />
        </div>
        <div class="dyLabel-more flex flex-center wrap">
          <div
            class="dyLabel-more_item"
            v-for="(item, index) in detail.tag_arr"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="dybox flex column flex-center">
        <div class="dybox-nav flex flex-center">
          <div
            class="dybox-nav_item flex-item_center"
            :class="navIndex == index ? 'active-nav_item' : ''"
            v-for="(item, index) in nav"
            @click="navClick(index)"
            :key="index"
          >
            {{ item }}
          </div>
        </div>

        <!-- 动态 -->
        <div class="flex column" v-if="navIndex == 0">
          <div
            class="dybox-dynamic"
            v-for="(item, index) in dynamic"
            :key="index"
          >
            <div class="dybox-dynamic_year" v-if="item.arr">
              {{ item.arr[0] }}
            </div>
            <div class="dybox-dynamic_time" v-if="item.arr">
              {{ item.arr[1] }}
            </div>
            <div>{{ item.desc }}</div>
          </div>
        </div>

        <!-- 跟进 -->
        <div class="flex column" v-if="navIndex == 1">
          <div
            class="dybox-dynamic"
            v-for="(item, index) in follow"
            :key="index"
          >
            <div class="dybox-dynamic_year" v-if="item.arr">
              {{ item.arr[0] }}
            </div>
            <div class="dybox-dynamic_time" v-if="item.arr">
              {{ item.arr[1] }}
            </div>
            <div class="dybox-dynamic_chat">{{ item.title }}</div>
            <div class="dybox-dynamic_content">
              {{ item.content }}
            </div>
            <div class="rightullidiv">
              <img :src="item.img_url" alt="" class="rightulliimg" />
            </div>
          </div>
        </div>
      </div>

      <div class="while"></div>
    </div>
    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-add flex-item_center" @click="router('/CreateFollow')">
        添加跟进
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      list: [
        { name: "电话", img: require("../assets/imgs/customer-phone.png") },
        { name: "单聊", img: require("../assets/imgs/customer-chat.png") },
        // { name: "群聊", img: require("../assets/imgs/customer-wechat.png") },
      ],
      nav: ["动态", "跟进"],
      navIndex: 0,
      detail: "",
      page: 1,
      dynamic: [],
      follow: [],
    };
  },
  async mounted() {
    let that = this;
    this._getDynamic();
    let _res = await request._getUserDetail({
      id: this.$route.query.id,
    });
    if (_.toInteger(_res.code) === 1) {
      this.detail = _res.data.data;
    }

    let res = await request._getConfig({
      url: location.href.split("#")[0],
    });
    console.log("获取配置", res);
    if (_.toInteger(res.code) == 1) {
      let result = res.data;
      that.$wx.config({
        beta: true,
        debug: false, // 关闭调试模式
        appId: result.corpid, // 必填，公众号的唯一标识
        timestamp: result.timestamp, // 必填，生成签名的时间戳
        nonceStr: result.nonceStr, // 必填，生成签名的随机串
        signature: result.signature, // 必填，签名
        jsApiList: ["openEnterpriseChat"], // 必填，需要使用的JS接口列表
      });
      that.$wx.ready(function() {
        that.$wx.agentConfig({
          corpid: result.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: result.agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.timestamp, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ["openEnterpriseChat"], //必填，传入需要使用的接口名称
          success: function(res) {
            console.log("res", res);
            // 回调
          },
          fail: function(res) {
            console.log("res err", res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      });
    }
  },
  methods: {
    // 查看客户动态
    async _getDynamic() {
      let res = await request._getDynamic({
        userid: localStorage.getItem("userid"),
        contact_id: this.$route.query.external_userid,
        page: this.page,
      });
      // console.log("res", res)
      if (_.toInteger(res.code) === 1) {
        var result = res.data.data;
        result.forEach((item) => {
          item.arr = item.add_time.split(" ");
        });
        console.log("Result", result);
        this.dynamic = result;
      }
    },

    async _getFollow() {
      let res = await request._follow_list({
        userid: localStorage.getItem("userid"),
        contact_id: this.$route.query.external_userid,
        page: this.page,
      });
      console.log("跟进", res);
      if (_.toInteger(res.code) === 1) {
        var result = res.data;
        result.forEach((item) => {
          item.add_time ? (item.arr = item.add_time.split(" ")) : "";
        });
        console.log("Result", result);
        this.follow = result;
      }
    },
    click(index) {
      if (index == 1) {
        // 单聊
        this.chat();
      } else if (index == 0) {
        if (this.detail.remark_mobiles) {
          window.location.href = "tel://" + this.detail.remark_mobiles[0];
        } else {
          Toast("请先给客户备注电话");
        }
      }
    },
    chat() {
      this.$wx.openEnterpriseChat({
        userIds: this.detail.employee_id,
        externalUserIds: this.detail.external_userid,
        groupName: "会话",
        chatId: "",
        success: function(res) {
          console.log("Res", res);
          var chatId = res.chatId; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: function(res) {
          console.log("res", res);
          if (res.errMsg.indexOf("function not exist") > -1) {
            alert("版本过低请升级");
          }
        },
      });
    },
    navClick(index) {
      this.navIndex = index;
      this.page = 1;
      index == 0 ? this._getDynamic() : this._getFollow();
    },
    router(path) {
      this.$router.push({
        path,
        query: {
          id: this.$route.query.id,
          external_userid: this.$route.query.external_userid,
        },
      });
    },
  },
};
</script>

<style scoped>
.rightullidiv {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  align-items: center;
}

.rightulliimg {
  max-width: 100%;
  max-height: 700px;
}

.dy {
  width: 100%;
  height: 529px;
  background: url(../assets/imgs/cusBg.png);
  background-size: 100% 100%;
}

.dycontent {
  justify-content: space-between;
  font-size: 26px;
  height: 417px;
  padding: 0 30px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.dycontent-img {
  width: 119px;
  height: 119px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0 19px 0 0;
}

.dycontent-name {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}

.dycontent-label {
  padding: 5px 13px 5px 9px;
  margin: 0 0 0 16px;
  background: #6f88f4;
  border-radius: 20px 20px 20px 20px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.dycontent-integral {
  font-size: 26px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 26px;
}

.dycontent-detail {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  height: 20px;
}

.dyList {
  margin: -80px auto;
  width: 690px;
  height: 207px;
  background: linear-gradient(180deg, #e8efff 0%, #ffffff 19%, #ffffff 100%);
  box-shadow: 0px 10px 50px 1px rgba(161, 182, 248, 0.27);
  border-radius: 24px 24px 24px 24px;
  justify-content: space-between;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
}

.dyList-item {
  /*width: 33.3%;*/
  width: 50%;
}
.dyList img {
  width: 72px;
  height: 72px;
  margin-bottom: 10px;
}
.dyList-line {
  width: 1px;
  height: 59px;
  right: 0;
  top: 20px;
  background: #d6e5ee;
}

.dyLabel {
  margin: 128px auto 16px;
  width: 690px;
  min-height: 217px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.dyLabel-top {
  padding: 30px 0;
  width: 630px;
  border-bottom: 1px solid #d6e5ee;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
  justify-content: space-between;
}

.dyLabel-top img {
  width: 12px;
  height: 20px;
}
.dyLabel-more {
  width: 630px;
  padding: 28px 0 42px 0;
}

.dyLabel-more_item {
  background: #edf3ff;
  border-radius: 4px 4px 4px 4px;
  padding: 7px 39px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
  margin: 0 16px 16px 0;
}

.dybox {
  width: 690px;
  margin: 0 auto;
  padding: 24px 0;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
}

.dybox-nav {
  width: 630px;
  height: 98px;
  background: #f4f5f7;
  border-radius: 16px 16px 16px 16px;
  padding: 0 54px;
  justify-content: space-between;
}

.dybox-nav_item {
  width: 199px;
  height: 64px;
}

.active-nav_item {
  background: #ffffff;
  box-shadow: 0px 4px 8px 1px #eaebee;
  border-radius: 8px 8px 8px 8px;
  color: #0558f4;
}

.dybox-dynamic {
  padding: 43px 0 25px 0;
  width: 630px;
  border-bottom: 1px solid #d6e5ee;
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
}

.dybox-dynamic_year {
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.dybox-dynamic_time {
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
  padding: 24px 0 10px 0;
}
.dybox-dynamic_chat {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.dybox-dynamic_content {
  margin-top: 13px;
  width: 600px;
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
}

.dybox-dynamic_img {
  width: 135px;
  height: 135px;
  margin-top: 24px;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.while {
  height: 200px;
}

.dycontent-detailIcon {
  width: 12px;
  height: 20px;
  margin-left: 10px;
}

.dycontent-vipIcon {
  width: 32px;
  height: 29px;
  margin-right: 3px;
}
</style>
